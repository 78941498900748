<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import {ElMessage} from "element-plus";
import {mapState} from "vuex";
import {getSigner} from "@/utils/wallet";
import {AINPC_ABI} from "@/store/AINPC_ABI";
import {ethers} from "ethers";

const zero = '0x0000000000000000000000000000000000000000'
const ainpc_address = '0x06A5b5f3E0fAc6ceBE15EA894Df2F6F04bb91401';

export default {
  name: "AirDropView",
  components: {FooterComponent, HeaderComponent},
  data: function () {
    return {
      carousels: [
        {
          imageUrl: "/images/1.png",
        },
        {
          imageUrl: "/images/2.png",
        },
        // {
        //   imageUrl: "https://33bnb.meme/images/banner2.png",
        // }
      ],
      amount: 0,
      rewards: 0,
      invitor: zero,
      loading1: false,
      loading2: false,
    }
  },
  computed: {
    ...mapState(['wallet_address', 'wallet_type']), // 映射 state
  },
  methods: {
    async onClickMint() {

      if (!this.wallet_address) {
        ElMessage.error({
          message: 'Please connect wallet first.',
          duration: 3000,
          center: true,
        });
        return;
      }
      if (this.loading1) {
        return
      }
      this.loading1 = true

      try {

        const signerInfo = await getSigner(this.wallet_type); // Get the signer information
        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        const contract = new ethers.Contract(ainpc_address, AINPC_ABI, signer)

        const value = ethers.parseEther((this.amount * 0.1).toString());
        console.log(value)

        const tx = await contract.mint(this.invitor, {
          value: value
        });

        console.log('tx', tx);

        ElMessage.success({
          message: 'send tx: ' + tx.hash,
        });

        await tx.wait();
        ElMessage.success({
          message: 'Mint success.',
          duration: 3000,
          center: true,
        });

      } catch (e) {
        // ElMessage.error('Error: ' + e);
        console.log(e)
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        }
      } finally {
        this.loading1 = false
      }
    },
    async onClickCollect() {
      if (!this.wallet_address) {
        ElMessage.error('Please connect wallet first.');
        return;
      }

      if (this.loading2 == true) {
        return
      }

      this.loading2 = true

      try {
        const signerInfo = await getSigner(this.wallet_type); // Get the signer information
        // Check if signerInfo is an array with two elements
        let provider, signer;
        if (Array.isArray(signerInfo) && signerInfo.length === 2) {
          [provider, signer] = signerInfo;
        } else {
          throw new Error('getSigner() did not return an array with provider and signer');
        }

        const contract = new ethers.Contract(ainpc_address, AINPC_ABI, signer)

        const tx = await contract.collectReward();

        console.log('tx', tx);

        ElMessage.success({
          message: 'send tx: ' + tx.hash,
        });

        await tx.wait();
        ElMessage.success({
          message: 'Mint success.',
          duration: 3000,
          center: true,
        });

      } catch (e) {
        // ElMessage.error('Error: ' + e);
        console.log(e)
        if (e.reason) {
          ElMessage.error(e.reason)
        } else if (e.shortMessage) {
          ElMessage.error(("Transaction failed: " + e.shortMessage));
        }
      } finally {
        this.loading2 = false
      }

    },
    async loadReward() {
      if (!this.wallet_address) {
        return;
      }

      const signerInfo = await getSigner(this.wallet_type); // Get the signer information
      // Check if signerInfo is an array with two elements
      let provider, signer;
      if (Array.isArray(signerInfo) && signerInfo.length === 2) {
        [provider, signer] = signerInfo;
      } else {
        throw new Error('getSigner() did not return an array with provider and signer');
      }

      const contract = new ethers.Contract(ainpc_address, AINPC_ABI, signer)
      const rewardRaw = await contract.rewards(this.wallet_address);
      this.rewards = ethers.formatEther(rewardRaw);
      console.log("rewards", this.rewards)
    },
    onClickCopy() {
      if (!this.wallet_address) {
        ElMessage.error('Please connect wallet first.');
        return;
      }
      const url = "https://33bnb.meme/airdrop?invitor=" + this.wallet_address;
      navigator.clipboard.writeText(url);
      ElMessage.success('Copy success');
    }
  },
  mounted() {
    const invitor = new URLSearchParams(window.location.search).get("invitor");
    console.log("invitor", invitor)
    if (invitor) {
      this.invitor = invitor
    }
    setInterval(() => {
      this.loadReward()
    }, 3000)
  },
}
</script>

<template>
  <div>
    <HeaderComponent/>
    <div class="index">
      <div class="section8">
        <div class="wrap">
          <div class="left">

            <!--            <div class="swiper-container">-->
            <!--              <div class="swiper-wrapper">-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner2.png" alt="">-->
            <!--                </div>-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner2.png" alt="">-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="swiper-pagination"></div>-->
            <!--            </div>-->

            <el-carousel class="el-carousel">
              <el-carousel-item class="el-carousel-item" v-for="carousel in carousels">
                <img :src="carousel.imageUrl" alt="">
              </el-carousel-item>
            </el-carousel>

          </div>
          <div class="right">
            <div class="ms1">
              <div class="s1">Points <br> Leaderboard</div>
              <div class="s2">
                <div class="m1">Dec 22 | 2024</div>
                <div class="m2"> 21:56</div>
              </div>
            </div>
            <div class="line"><img src="images/lineimg.png" alt=""></div>
            <div class="ms2">
              <p>
                <span class="s1">Total Points</span>
                <span class="s2">34,282,378</span>
              </p>
              <p>
                <span class="s1">Users</span>
                <span class="s2">28,036</span>
              </p>
            </div>
            <div class="ms3">
              <div class="title">
                <div class="s1">Rank</div>
                <div class="s2">
                  <p>Address</p>
                  <p>Points</p>
                </div>
              </div>
              <div class="list">
                <div class="item ">
                  <div class="s1">None <br>Rank</div>
                  <div class="s2 orange">
                    <div class="m1">
                      <div class="icon"><img src="images/fd.png" alt=""></div>
                      <div class="val">0xff...04cb48</div>
                    </div>
                    <div class="m2">0</div>
                  </div>
                </div>
                <div class="item">
                  <div class="s1">1</div>
                  <div class="s2 black">
                    <div class="m1">
                      <div class="icon"><img src="images/or.png" alt=""></div>
                      <div class="val">0xff...04cb48</div>
                    </div>
                    <div class="m2">0</div>
                  </div>
                </div>
                <div class="item">
                  <div class="s1">2</div>
                  <div class="s2">
                    <div class="m1">
                      <div class="icon"><img src="images/fd.png" alt=""></div>
                      <div class="val">0xff...04cb48</div>
                    </div>
                    <div class="m2">0</div>
                  </div>
                </div>
                <div class="item">
                  <div class="s1">3</div>
                  <div class="s2">
                    <div class="m1">
                      <div class="icon"><img src="images/fd.png" alt=""></div>
                      <div class="val">0xff...04cb48</div>
                    </div>
                    <div class="m2">0</div>
                  </div>
                </div>
                <div class="item">
                  <div class="s1">4</div>
                  <div class="s2">
                    <div class="m1">
                      <div class="icon"><img src="images/fd.png" alt=""></div>
                      <div class="val">0xff...04cb48</div>
                    </div>
                    <div class="m2">0</div>
                  </div>
                </div>
                <div class="item">
                  <div class="s1">5</div>
                  <div class="s2">
                    <div class="m1">
                      <div class="icon"><img src="images/fd.png" alt=""></div>
                      <div class="val">0xff...04cb48</div>
                    </div>
                    <div class="m2">0</div>
                  </div>
                </div>
                <div class="item">
                  <div class="s1">6</div>
                  <div class="s2">
                    <div class="m1">
                      <div class="icon"><img src="images/fd.png" alt=""></div>
                      <div class="val">0xff...04cb48</div>
                    </div>
                    <div class="m2">0</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section9">
        <div class="wrap">
          <div class="title">AINPC donation</div>
          <div class="content">
            <div class="s1">
              <div class="lef">
                <div class="icon"><img src="images/ic2d.png" alt=""></div>
                <input type="number" max="100" v-model="amount" class="words">
              </div>
              <button class="btn" @click="onClickMint" v-loading="loading1">Mint Now</button>
            </div>
            <div class="s2">
              <button class="btn1" @click="onClickCollect" v-loading="loading2">Collect：{{ rewards }} BNB</button>
              <button class="btn2" @click="onClickCopy">Invitation</button>
            </div>
          </div>
          <div class="words">Available Time : 12/02 0:00 - 12/31 23:59 (UTC+0)</div>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<style scoped>
.el-carousel {
  width: 100%;
  height: auto; /* 允许高度自适应 */
  aspect-ratio: 2100 / 1170; /* 保持宽高比 */
  //margin-bottom: 16px;
  border-radius: 20px;
}

.el-carousel-item {
  width: 100%;
  height: auto; /* 允许高度自适应 */
  aspect-ratio: 2100 / 1170; /* 保持宽高比 */
}

.el-carousel img {
  width: 100%; /* 确保图片宽度为100% */
  height: auto; /* 允许高度自适应 */
  object-fit: fill; /* 保持图片比例，裁剪多余部分 */
}

.el-message {
  z-index: 99999 !important;
}
</style>
