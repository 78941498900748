<script>
import FooterComponent from "@/components/FooterComponent.vue";
import HeaderComponent from "@/components/HeaderComponent.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "DetailView",
  components: {Pagination, HeaderComponent, FooterComponent},
  data: function () {
    return {
      token: {
        name: 'Loading...',
        symbol: 'LD',
        creatorAddress: 'N/A',
        iconUrl: '',
        price: 0,
        marketCap: 0,
        bondingCurveProgress: 0,
        remain: 0,
        collateral: 0,
        state: '',
        virtualLiquidity: 0,
        VolumeIn24: 0,
        rise: 0
      },
      can_buy_amount: 0,
      can_sell_amount: 0,
      comment: '', // New property for comment input
      commentLength: 0, // To track the length of the comment
      maxCommentLength: 256, // Maximum allowed length
      comments: [], // To hold submitted comments
      tab: 'buy',
      amount: 0,
      currentPage: 1,
      totalPages: 10,
      token_address: "",
      eth_balance: 0,
      token_balance: 0,
      slippage_tolerance: 5,
      eth_price: 0,
      holders: [],
      isVisible: false,
      tab2: 'comment',
      loading: false,
      loading_amount: false,
    };
  },
  computed: {},
  methods: {
    onClickSelectTab(tab) {
      this.tab = tab
    },
    handlePageChange(num) {
      console.log('handlePageChange', num)
    }
  },
  mounted() {

  }
}
</script>

<template>
  <div>
    <HeaderComponent/>
    <div class="index">
      <div class="section1">
        <div class="wrap">
          <div class="left">
            <div class="block1">
              <div class="title">
                <img src="images/ic1.png" alt="">
                <img src="images/ic2.png" class="sec" alt="">
                <span>NAME</span>
                <b>|</b>
                <strong>BNB</strong>
              </div>
              <div class="items">
                <div class="item">
                  <div class="tit">$1.35K</div>
                  <div class="dec">Market Cap</div>
                  <b>+0.04%</b>
                </div>
                <div class="item">
                  <div class="tit">$2.48K</div>
                  <div class="dec">Virtual Liquidity</div>
                </div>
                <div class="item">
                  <div class="tit">$0.13</div>
                  <div class="dec">Volume</div>
                </div>
                <div class="item">
                  <div class="tit">0.0013456083</div>
                  <div class="dec">Rise</div>
                  <strong>USDT</strong>
                </div>
                <div class="item">
                  <div class="tit">0xda...862c93</div>
                  <div class="dec">CA</div>
                  <div class="icon"><img src="images/icr.png" class="im1" alt=""></div>
                </div>
              </div>
            </div>
            <div class="block2">
              <!--                            <img src="images/charts.png" alt="">-->
              <!--                            <iframe id="tradingview_6aaa1" name="tradingview_6aaa1" src="https://www.dextools.io/461fc3f6-ba64-4882-b2fb-021b5a5c753e" data-widget-options="symbol=Jeff%2FUSD%20-%20DEX&amp;interval=30&amp;widgetbar=%7B%22details%22%3Afalse%2C%22watchlist%22%3Afalse%2C%22news%22%3Afalse%2C%22datawindow%22%3Afalse%2C%22watchlist_settings%22%3A%7B%22default_symbols%22%3A%5B%5D%7D%7D&amp;timeFrames=%5B%7B%22text%22%3A%225y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%221y%22%2C%22resolution%22%3A%221W%22%7D%2C%7B%22text%22%3A%226m%22%2C%22resolution%22%3A%22120%22%7D%2C%7B%22text%22%3A%223m%22%2C%22resolution%22%3A%2260%22%7D%2C%7B%22text%22%3A%221m%22%2C%22resolution%22%3A%2230%22%7D%2C%7B%22text%22%3A%225d%22%2C%22resolution%22%3A%225%22%7D%2C%7B%22text%22%3A%221d%22%2C%22resolution%22%3A%221%22%7D%5D&amp;locale=en&amp;uid=tradingview_6aaa1&amp;clientId=0x8946c177d4bd65d3be1f60d302eefc51937d2e18-arbitrum&amp;userId=0&amp;chartsStorageVer=1&amp;customCSS=css%2Fcustom_dext_light_dark.css%3F1.0.0&amp;autoSaveDelay=2&amp;debug=false&amp;timezone=Asia%2FShanghai&amp;theme=dark" title="Financial Chart" frameborder="0" allowtransparency="true" scrolling="no" allowfullscreen="" style="display: block; width: 100%; height: 400px;">-->
              <!--                            </iframe>-->

              <iframe id="dextools-widget"
                      frameborder="0"
                      style="width: 100%;aspect-ratio: 2100 / 1300;border-radius: 20px"
                      title="DEXTools Trading Chart"
                      src="https://www.dextools.io/widget-chart/en/ether/pe-light/0xa29fe6ef9592b5d408cca961d0fb9b1faf497d6d?theme=dark&chartType=2&chartResolution=30&drawingToolbars=false">
              </iframe>

            </div>
          </div>
          <div class="right">
            <div class="block1"
                 :style="{
              'background': tab !== 'set'?'#e1eadf':'#212d26'
              }"
            >
              <div class="hd">
                <div class="hda"
                     :class="[
                     { 'hide': tab === 'set' }]"
                >
                  <a href="javascript:;" :class="{'current': tab === 'buy'}"
                     @click="onClickSelectTab('buy')"> Buy</a>
                  <a href="javascript:;"
                     :class="[
                      { 'current': tab === 'sell' || tab === 'set' },
                     ]"
                     :style="{
                        'border-radius': tab !== 'set' ?'calc(30/1920*100vw) calc(30/1920*100vw) 0 0':'calc(30/1920*100vw) 0 0 0'
                     }"
                     @click="onClickSelectTab('sell')"> Sell</a>
                </div>
                <a href="javascript:;" class="set" :class="{'current': tab === 'set'}" @click="onClickSelectTab('set')">
                  <img src="images/set.png" alt="">
                </a>
              </div>
              <div class="bd">
                <div class="modelItem" v-show="tab==='buy'">
                  <div class="model">
                    <div class="ms1">
                      <div class="s1">Balance: 0 BNB</div>
                      <div class="s2">Base on PancakeSwap</div>
                    </div>
                    <div class="ms2">
                      <div class="inputwords">
                        <input type="text" class="words">
                        <div class="posa">
                          BNB
                          <img src="images/if.png" alt="">
                        </div>
                      </div>
                      <div class="switch">Switch to NAME</div>
                    </div>
                    <button class="btns" type="button" style="font-size: calc(18 / 1920* 100vw)">Trade</button>
                    <div class="txt">The Pool was migrated to PancakeSwap</div>
                  </div>
                </div>
                <div class="modelItem" v-show="tab==='sell'">
                  <div id="model3"
                       :style="{
                        'border-radius': tab !== 'set' ?'0 calc(30 / 1920* 100vw) calc(30 / 1920* 100vw) 0':'0 0 calc(30 / 1920* 100vw) 0'
                     }">
                    <div class="ms_1">
                      <p>Available : 0</p>
                      <b>Base on PancakeSwap</b>
                    </div>
                    <div class="ms_2">
                      <div class="inputwords">
                        <input type="text" placeholder="0" class="words">
                        <div class="posa">
                          BNB
                          <img src="images/if.png" alt="">
                        </div>
                      </div>
                      <div class="dec2">
                        <p>
                          <a href="#">25%</a>
                        </p>
                        <p>
                          <a href="#">50%</a>
                        </p>
                        <p>
                          <a href="#">75%</a>
                        </p>
                        <p>
                          <a href="#">100%</a>
                        </p>
                      </div>
                    </div>
                    <button class="btn" type="button">Trade</button>
                    <div class="desc2">The Pool was migrated to PancakeSwap</div>
                  </div>
                </div>
              </div>
              <div id="setmodel" style="display: block;    border-radius:  calc(30 / 1920* 100vw) 0 0 0;"
                   v-show="tab==='set'">
                <div class="model1">
                  <div class="ms1">
                    <div class="inputwords">
                      <input type="text" placeholder="Max Slippage" class="words">
                      <div class="posa">
                        5%
                      </div>
                    </div>
                    <div class="dec">Modify the max slippages for your trades. Default: 5%</div>
                  </div>
                  <div class="tiswti">
                    <div class="tit">MEV protection</div>
                    <div class="switchModel">
                      <input type="checkbox" name="Storage" id="switch1">
                      <label for="switch1">
                        <em></em>
                      </label>
                    </div>
                  </div>
                  <div class="desc">After MEV protection is turned on, front-running and sandwich attacks will be
                    effectively prevented. (Metamask wallet only)
                  </div>
                  <button class="btn" type="button">SAVE</button>
                </div>
              </div>
            </div>
            <div class="block2">
              <div class="ms1">
                <div class="icon"><img src="images/user.png" alt=""></div>
                <div class="infor">
                  <div class="name">Daredevil Dog ...</div>
                  <div class="dec">created by: 0x0c...817024</div>
                </div>
                <a href="#" class="share"><img src="images/arr.png" alt=""></a>
              </div>
              <div class="ms2">Daredevil Dog is here from the depths of hell to take over Solana</div>
            </div>
          </div>
        </div>
      </div>
      <div class="section2">
        <div class="wrap">
          <div class="left">
            <div class="modeltab" style="display: block" v-show="tab2==='comment'">
              <div class="title">
                <img src="images/titleimg2.png" alt="">
                <a href="javascript:;" class="wallet" @click="tab2='history'">
                  <img src="images/wl.png" alt="">
                  <span>Trades</span>
                </a>
              </div>
              <div class="tit">Total 3</div>
              <div class="items">
                <div class="item">
                  <div class="s1">
                    <img src="images/cu1.png" alt="">
                    <div class="infor">
                      <div class="s1">0xff...04cb48</div>
                      <div class="s2">4 hours ago</div>
                    </div>
                  </div>
                  <div class="s2">
                    https://springboard.pancakeswap.finance/bsc/token/0xd52d5e3f550f19d68381c46da0c3e624c97c7666
                  </div>
                </div>
                <div class="item">
                  <div class="s1">
                    <img src="images/cu1.png" alt="">
                    <div class="infor">
                      <div class="s1">0xff...04cb48</div>
                      <div class="s2">4 hours ago</div>
                    </div>
                  </div>
                  <div class="s2">
                    gogo
                  </div>
                </div>
                <div class="item">
                  <div class="s1">
                    <img src="images/cu1.png" alt="">
                    <div class="infor">
                      <div class="s1">0xff...04cb48</div>
                      <div class="s2">4 hours ago</div>
                    </div>
                  </div>
                  <div class="s2">
                    +1
                  </div>
                </div>
              </div>
              <div class="textareaform">
                <textarea name="" style="color: black" class="words" placeholder="Write your comment..." id=""></textarea>
                <div class="totals">0/100</div>
                <button class="btn" type="button">Submit</button>
              </div>
              <div class="pagesize">
                <div class="brad">
                  <a href="#" class="">
                    <img src="images/left.png" alt="">
                  </a>
                  <a href="#" class="current">1</a>
                  <a href="#">2</a>
                  <a href="#">3</a>
                  <a href="#">4</a>
                  <a href="#">5</a>
                  <input type="text" class="words" placeholder="...">
                  <a href="#">12</a>
                  <a href="#">
                    <img src="images/right.png" alt="">
                  </a>
                </div>
              </div>
            </div>
            <div class="modeltab" style="display: block" v-show="tab2==='history'">
              <div class="tableDetail">
                <div class="title">
                  <div class="titlePic">
                    <img src="images/titleimg.png" alt="">
                  </div>
                  <a href="javascript:;" class="wallet" @click="tab2='comment'">
                    <img src="images/wl2.png" alt="">
                    <span>Comments</span>
                  </a>
                </div>
                <div class="tablelist">
                  <table width="100%" border="0" cellspacing="0" cellpadding="0" class="">
                    <tr>
                      <th>ACCOUNT&DATE</th>
                      <th>USD</th>
                      <th>BNB</th>
                      <th>SANTA</th>
                      <th>DATE</th>
                      <th>TXN</th>
                    </tr>
                    <tr class="red">
                      <td>
                        <div class="txt1">
                          <img src="images/ifg1.png" alt="">
                          <span>0xff...04cb48</span>
                        </div>
                      </td>
                      <td>14.684</td>
                      <td>0.020</td>
                      <td>1,190,721.93137345</td>
                      <td>4 hours ago</td>
                      <td>
                        <a href="#" class='cur'>
                          <img src="images/link.png" alt="">
                        </a>
                      </td>
                    </tr>
                    <tr class="green">
                      <td>
                        <div class="txt1">
                          <img src="images/ifg1.png" alt="">
                          <span>0xff...04cb48</span>
                        </div>
                      </td>
                      <td>14.684</td>
                      <td>0.020</td>
                      <td>1,190,721.93137345</td>
                      <td>4 hours ago</td>
                      <td>
                        <a href="#" class='cur'>
                          <img src="images/link.png" alt="">
                        </a>
                      </td>
                    </tr>
                    <tr class="green">
                      <td>
                        <div class="txt1">
                          <img src="images/ifg1.png" alt="">
                          <span>0xff...04cb48</span>
                        </div>
                      </td>
                      <td>14.684</td>
                      <td>0.020</td>
                      <td>1,190,721.93137345</td>
                      <td>4 hours ago</td>
                      <td>
                        <a href="#" class='cur'>
                          <img src="images/link.png" alt="">
                        </a>
                      </td>
                    </tr>
                    <tr class="green">
                      <td>
                        <div class="txt1">
                          <img src="images/ifg1.png" alt="">
                          <span>0xff...04cb48</span>
                        </div>
                      </td>
                      <td>14.684</td>
                      <td>0.020</td>
                      <td>1,190,721.93137345</td>
                      <td>4 hours ago</td>
                      <td>
                        <a href="#" class='cur'>
                          <img src="images/link.png" alt="">
                        </a>
                      </td>
                    </tr>
                    <tr class="green">
                      <td>
                        <div class="txt1">
                          <img src="images/ifg1.png" alt="">
                          <span>0xff...04cb48</span>
                        </div>
                      </td>
                      <td>14.684</td>
                      <td>0.020</td>
                      <td>1,190,721.93137345</td>
                      <td>4 hours ago</td>
                      <td>
                        <a href="#" class='cur'>
                          <img src="images/link.png" alt="">
                        </a>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="ms1">
              <div class="item" style="width: 40%;">
                <div class="s1">Bonding Curve</div>
                <div class="s2">40%</div>
              </div>
              <div class="item" style="width: 60%;">
                <div class="s1">For Sale</div>
                <div class="s2">60%</div>
              </div>
            </div>
            <div class="ms2">
              <p>There are <b>0.0 NAME</b> still available for sale in the bonding curve and there is <b>4.000000
                BNB</b> in the bonding curve.</p>
              <p>When the market cap reaches <b>$6,395.09</b> all the liquidity fromthe bonding curve will be deposited
                into Pancake and burned. Progression increases as the price goes up.</p>
            </div>
            <div class="ms3">
              <div class="model1">
                <div class="s1">Holder& <br>Percentage</div>
                <div class="s2">
                  <div class="total">Total Supply</div>
                  <div class="num">1,000,000</div>
                </div>
              </div>
              <div class="line"></div>
              <div class="model2">
                <div class="item type2">
                  <div class="length" style="width:60%">
                    <span>Liquidity Pool Token</span>
                    <span>40%</span>
                  </div>
                  <div class="tit">9550762b</div>
                </div>
                <div class="item">
                  <div class="length">
                    <span>6dc62a72</span>
                    <b>0%</b>
                  </div>
                </div>
                <div class="item">
                  <div class="length">
                    <span>6dc62a72</span>
                    <b>0%</b>
                  </div>
                </div>
                <div class="item">
                  <div class="length">
                    <span>6dc62a72</span>
                    <b>0%</b>
                  </div>
                </div>
                <div class="item">
                  <div class="length">
                    <span>6dc62a72</span>
                    <b>0%</b>
                  </div>
                </div>
                <div class="item">
                  <div class="length">
                    <span>6dc62a72</span>
                    <b>0%</b>
                  </div>
                </div>
                <div class="item">
                  <div class="length">
                    <span>6dc62a72</span>
                    <b>0%</b>
                  </div>
                </div>
                <div class="item">
                  <div class="length">
                    <span>6dc62a72</span>
                    <b>0%</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<style scoped>
.iframe-container {
  width: 100%;
  height: 100vh; /* Adjust as necessary */
  overflow: hidden;
}

#iframe {
  width: 100%;
  height: 100%;
  border: none; /* Ensures no border */
}

.dextools-widget {
  width: 100%;
  height: 400px;
  border-radius: 20px;
}
</style>