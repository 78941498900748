<template>
  <div class="home">
    <HeaderComponent/>
    <div class="index">
      <div class="section3_1">
        <div class="wrap">
          <div class="left">
            <img src="images/me1.png" class="me1" alt="">
            <img src="images/me2.png" class="me2" alt="">
            <router-link to="/airdrop" class="more">Create Token</router-link>
            <a href="#" class="how">How it works</a>
          </div>
          <div class="center">
            <el-carousel class="el-carousel">
              <el-carousel-item class="el-carousel-item" v-for="carousel in carousels">
                <img :src="carousel.imageUrl" alt="">
              </el-carousel-item>
            </el-carousel>
            <!--            <div class="swiper-container">-->
            <!--              <div class="swiper-wrapper">-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner.jpg" alt="">-->
            <!--                </div>-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner.jpg" alt="">-->
            <!--                </div>-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner.jpg" alt="">-->
            <!--                </div>-->
            <!--                <div class="swiper-slide">-->
            <!--                  <img src="images/banner.jpg" alt="">-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="swiper-pagination"></div>-->
            <!--            </div>-->
          </div>
          <div class="right">
            <p>
              0x0c...817024 <span>Sold</span> 0.88BNB
            </p>
            <p>
              <span class="icon"><img src="images/cm.png" alt=""></span>
            </p>
          </div>
        </div>
      </div>
      <div class="section4">
        <div class="wrap">
          <div class="title">Listed on Pancake Swap</div>
          <div class="list">
            <div class="item" @click="toDetail('')">
              <div class="s1">
                <div class="icon"><img src="images/user.png" alt=""></div>
                <div class="infor">
                  <div class="name">NAME</div>
                  <div class="dec">created by: 0x0c...817024</div>
                </div>
              </div>
              <div class="s2">
                <img src="images/user2.png" alt="">
                <div class="infor">
                  <div class="lef">
                    <div class="m1">Market Cap:4.35 K</div>
                    <div class="m2">100%</div>
                  </div>
                  <div class="ris">
                    <img src="images/ic2d.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="s1">
                <div class="icon"><img src="images/user.png" alt=""></div>
                <div class="infor">
                  <div class="name">NAME</div>
                  <div class="dec">created by: 0x0c...817024</div>
                </div>
              </div>
              <div class="s2">
                <img src="images/user2.png" alt="">
                <div class="infor">
                  <div class="lef">
                    <div class="m1">Market Cap:4.35 K</div>
                    <div class="m2">100%</div>
                  </div>
                  <div class="ris">
                    <img src="images/ic2d.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="s1">
                <div class="icon"><img src="images/user.png" alt=""></div>
                <div class="infor">
                  <div class="name">NAME</div>
                  <div class="dec">created by: 0x0c...817024</div>
                </div>
              </div>
              <div class="s2">
                <img src="images/user2.png" alt="">
                <div class="infor">
                  <div class="lef">
                    <div class="m1">Market Cap:4.35 K</div>
                    <div class="m2">100%</div>
                  </div>
                  <div class="ris">
                    <img src="images/ic2d.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="s1">
                <div class="icon"><img src="images/user.png" alt=""></div>
                <div class="infor">
                  <div class="name">NAME</div>
                  <div class="dec">created by: 0x0c...817024</div>
                </div>
              </div>
              <div class="s2">
                <img src="images/user2.png" alt="">
                <div class="infor">
                  <div class="lef">
                    <div class="m1">Market Cap:4.35 K</div>
                    <div class="m2">100%</div>
                  </div>
                  <div class="ris">
                    <img src="images/ic2d.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="s1">
                <div class="icon"><img src="images/user.png" alt=""></div>
                <div class="infor">
                  <div class="name">NAME</div>
                  <div class="dec">created by: 0x0c...817024</div>
                </div>
              </div>
              <div class="s2">
                <img src="images/user2.png" alt="">
                <div class="infor">
                  <div class="lef">
                    <div class="m1">Market Cap:4.35 K</div>
                    <div class="m2">100%</div>
                  </div>
                  <div class="ris">
                    <img src="images/ic2d.png" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="information">
            <input type="text" class="search" placeholder="Search Token">
            <div class="right">
              <div class="item">
                <img src="images/cirl.png" alt="">
                <span>Listed on PancakeSwap</span>
              </div>
              <div class="item">
                <select name="" id="">
                  <option value="">Time</option>
                </select>
              </div>
              <div class="item">
                <select name="" id="">
                  <option value="">All Token</option>
                </select>
              </div>
              <div class="item">
                <a href="#"><img src="images/dm1.png" alt=""></a>
              </div>
              <div class="item">
                <a href="#"><img src="images/dm2.png" alt=""></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section5">
        <div class="wrap">
          <div class="item">
            <div class="radius">
              <div class="imgbox"><img src="images/pic.png" alt=""></div>
              <div class="con">
                <div class="title">Daredevil Dog ...</div>
                <div class="dec">Daredevil Dog is here from the depths of hell to take over Solana</div>
                <div class="infor">
                  <div class="lef">created by: <br>0x0c...817024</div>
                  <div class="ris">
                    <a href="#"><img src="images/id1.png" alt=""></a>
                    <a href="#"><img src="images/id2.png" alt=""></a>
                    <a href="#"><img src="images/id3.png" alt=""></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="con2">
              <div class="lef">
                <div class="txt1">Market Cap:4.35 K</div>
                <div class="txt2">100% <b>+8%</b></div>
              </div>
              <div class="ris">
                <img src="images/ic2d.png" alt="">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="radius">
              <div class="imgbox"><img src="images/pic.png" alt=""></div>
              <div class="con">
                <div class="title">Daredevil Dog ...</div>
                <div class="dec">Daredevil Dog is here from the depths of hell to take over Solana</div>
                <div class="infor">
                  <div class="lef">created by: <br>0x0c...817024</div>
                  <div class="ris">
                    <a href="#"><img src="images/id1.png" alt=""></a>
                    <a href="#"><img src="images/id2.png" alt=""></a>
                    <a href="#"><img src="images/id3.png" alt=""></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="con2">
              <div class="lef">
                <div class="txt1">Market Cap:4.35 K</div>
                <div class="txt2">100% <b>+8%</b></div>
              </div>
              <div class="ris">
                <img src="images/ic2d.png" alt="">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="radius">
              <div class="imgbox"><img src="images/pic.png" alt=""></div>
              <div class="con">
                <div class="title">Daredevil Dog ...</div>
                <div class="dec">Daredevil Dog is here from the depths of hell to take over Solana</div>
                <div class="infor">
                  <div class="lef">created by: <br>0x0c...817024</div>
                  <div class="ris">
                    <a href="#"><img src="images/id1.png" alt=""></a>
                    <a href="#"><img src="images/id2.png" alt=""></a>
                    <a href="#"><img src="images/id3.png" alt=""></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="con2">
              <div class="lef">
                <div class="txt1">Market Cap:4.35 K</div>
                <div class="txt2">100% <b>+8%</b></div>
              </div>
              <div class="ris">
                <img src="images/ic2d.png" alt="">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="radius">
              <div class="imgbox"><img src="images/pic.png" alt=""></div>
              <div class="con">
                <div class="title">Daredevil Dog ...</div>
                <div class="dec">Daredevil Dog is here from the depths of hell to take over Solana</div>
                <div class="infor">
                  <div class="lef">created by: <br>0x0c...817024</div>
                  <div class="ris">
                    <a href="#"><img src="images/id1.png" alt=""></a>
                    <a href="#"><img src="images/id2.png" alt=""></a>
                    <a href="#"><img src="images/id3.png" alt=""></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="con2">
              <div class="lef">
                <div class="txt1">Market Cap:4.35 K</div>
                <div class="txt2">100% <b>+8%</b></div>
              </div>
              <div class="ris">
                <img src="images/ic2d.png" alt="">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="radius">
              <div class="imgbox"><img src="images/pic.png" alt=""></div>
              <div class="con">
                <div class="title">Daredevil Dog ...</div>
                <div class="dec">Daredevil Dog is here from the depths of hell to take over Solana</div>
                <div class="infor">
                  <div class="lef">created by: <br>0x0c...817024</div>
                  <div class="ris">
                    <a href="#"><img src="images/id1.png" alt=""></a>
                    <a href="#"><img src="images/id2.png" alt=""></a>
                    <a href="#"><img src="images/id3.png" alt=""></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="con2">
              <div class="lef">
                <div class="txt1">Market Cap:4.35 K</div>
                <div class="txt2">100% <b>+8%</b></div>
              </div>
              <div class="ris">
                <img src="images/ic2d.png" alt="">
              </div>
            </div>
          </div>
          <div class="item">
            <div class="radius">
              <div class="imgbox"><img src="images/pic.png" alt=""></div>
              <div class="con">
                <div class="title">Daredevil Dog ...</div>
                <div class="dec">Daredevil Dog is here from the depths of hell to take over Solana</div>
                <div class="infor">
                  <div class="lef">created by: <br>0x0c...817024</div>
                  <div class="ris">
                    <a href="#"><img src="images/id1.png" alt=""></a>
                    <a href="#"><img src="images/id2.png" alt=""></a>
                    <a href="#"><img src="images/id3.png" alt=""></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="con2">
              <div class="lef">
                <div class="txt1">Market Cap:4.35 K</div>
                <div class="txt2">100% <b>+8%</b></div>
              </div>
              <div class="ris">
                <img src="images/ic2d.png" alt="">
              </div>
            </div>
          </div>
        </div>
        <Pagination
            :totalPages="totalPages"
            :currentPage="currentPage"
            @page-changed="handlePageChange"
        />
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import router from "@/router";
import Pagination from "@/components/Pagination.vue";

export default {
  name: 'HomeView',
  components: {
    Pagination,
    FooterComponent,
    HeaderComponent,
  },
  data: function () {
    return {
      carousels: [
        {
          imageUrl: "/images/01.png",
        },
        {
          imageUrl: "images/02.png",
        },
        // {
        //   imageUrl: "https://33bnb.meme/images/banner.jpg",
        // }
      ],
      page: 1,
      currentPage: 1,
      totalPages: 10,
      pageSize: 8,
      token_list: [],
      eth_price: 0,
      search_for_token: ""
    }
  },
  computed: {},
  methods: {
    toDetail(tokenAddress) {
      router.push('/detail?token=' + tokenAddress)
    },
    handlePageChange(num) {
      console.log('handlePageChange', num)
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.el-carousel {
  width: 100%;
  height: calc(349 / 1920 * 100vw); /* 允许高度自适应 */
  border-radius: 20px;
  //aspect-ratio: 799 / 337; /* 保持宽高比 */
  //margin-bottom: 16px;
}

.el-carousel-item {
  width: 100%;
  height: calc(349 / 1920 * 100vw); /* 允许高度自适应 */

  //aspect-ratio: 799 / 337; /* 保持宽高比 */
}

.el-carousel img {
  width: 100%; /* 确保图片宽度为100% */
  height: auto; /* 允许高度自适应 */
  object-fit: fill; /* 保持图片比例，裁剪多余部分 */
}
</style>

