<script>
export default {
  name: "FooterComponent",
  data : function () {

  },
  computed:{

  },
  methods:{

  },
  mounted() {

  }
}
</script>

<template>
  <div class="footer">
    <div class="block1">
      <div class="wrap">
        <div class="model1">
          <p>
            <a href="#"><img src="images/if.png" alt=""></a>
            <a href="#"><img src="images/if.png" alt=""></a>
            <a href="#"><img src="images/if.png" alt=""></a>
          </p>
          <p>Raised Token</p>
        </div>
        <div class="title">Binance Web3 Wallet</div>
        <div class="dec">Binance Web3 Wallet is a self-custody crypto wallet within the Binance app, designed to empower users in the realm of decentralized finance (DeFi). Serving as a digital gateway to blockchain-based applications (dApps), it offers users a secure and streamlined method to manage their cryptocurrencies, execute token swaps across multiple chains, earn yields, and interact with a variety of blockchain platforms.</div>
      </div>
    </div>
    <div class="block2">
      <div class="wrap">
        <div class="link">
          <a href="#"><img src="images/lis1.png" alt=""></a>
          <a href="#"><img src="images/lis2.png" alt=""></a>
          <a href="#"><img src="images/lis3.png" alt=""></a>
          <a href="#"><img src="images/lis4.png" alt=""></a>
        </div>
        <div class="dec">
          <p>
            Disclaimer: Digital assets are highly speculative and involve significant risk of loss. The value of meme coins is extremely volatile, and any one who wishes to trade in any meme coin should be prepared for the possibility of losing their entire investment. 33BNB.MEME makes no representations or warranties regarding the success or profitability of any meme coin developed on the platform. 33BNB.MEME is a public, decentralized, and permissionless platform. Participation by any project should not be seen as an endorsement or recommendation by FOUR.MEME. Users should assess their financial situation, risk tolerance, and do their own research before trading in any meme coins on the platform. FOUR.MEME will not be held liable for any losses, damages, or issues that may arise from trading in any meme coins developed on the platform. More information about (DYOR) can be found via <b>Binance Academy</b> and <b>Terms of Use</b>.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>