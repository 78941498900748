<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "RankingView",
  components: {FooterComponent, HeaderComponent},
  data: function () {

  },
  computed: {},
  methods: {},
  mounted() {

  }
}
</script>

<template>
  <div>
    <HeaderComponent/>
    <div class="index">
      <div class="section7">
        <div class="wrap">
          <div class="title">
            <div class="tit">Progress Ranking</div>
            <div class="ris">
              <p>24 Hours Gainers Ranking</p>
              <p>MarketCap Ranking</p>
              <p>24 Hours Trading Volume</p>
            </div>
          </div>
          <div class="dot">
            <b class="current"></b>
            <b></b>
            <b></b>
            <b></b>
          </div>
          <div class="items">
            <div class="item">
              <div class="tit">$36,260.205</div>
              <div class="val">98.482%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">LIL X</div>
                    <div class="icon2"><img src="images/fd.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="number">1</div>
            </div>
            <div class="item">
              <div class="tit">$36,260.205</div>
              <div class="val">98.482%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">LIL X</div>
                    <div class="icon2"><img src="images/fd.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="number">2</div>
            </div>
            <div class="item">
              <div class="tit">$36,260.205</div>
              <div class="val">98.482%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">LIL X</div>
                    <div class="icon2"><img src="images/fd.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="number">3</div>
            </div>
            <div class="item">
              <div class="tit">$36,260.205</div>
              <div class="val">98.482%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">LIL X</div>
                    <div class="icon2"><img src="images/fd.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="number">4</div>
            </div>
            <div class="item">
              <div class="tit">$36,260.205</div>
              <div class="val">98.482%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">LIL X</div>
                    <div class="icon2"><img src="images/fd.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="number">5</div>
            </div>
            <div class="item">
              <div class="tit">$36,260.205</div>
              <div class="val">98.482%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">LIL X</div>
                    <div class="icon2"><img src="images/fd.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="number">6</div>
            </div>
            <div class="item">
              <div class="tit">$36,260.205</div>
              <div class="val">98.482%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">LIL X</div>
                    <div class="icon2"><img src="images/fd.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="number">7</div>
            </div>
            <div class="item">
              <div class="tit">$36,260.205</div>
              <div class="val">98.482%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">LIL X</div>
                    <div class="icon2"><img src="images/fd.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="number">8</div>
            </div>
            <div class="item">
              <div class="tit">$36,260.205</div>
              <div class="val">98.482%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">LIL X</div>
                    <div class="icon2"><img src="images/fd.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="number">9</div>
            </div>
            <div class="item">
              <div class="tit">$36,260.205</div>
              <div class="val">98.482%</div>
              <div class="line">
                <div class="content">
                  <div class="icon"><img src="images/cs.png" alt=""></div>
                  <div class="bottom">
                    <div class="txt">LIL X</div>
                    <div class="icon2"><img src="images/fd.png" alt=""></div>
                  </div>
                </div>
              </div>
              <div class="number">10</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<style scoped>

</style>