<script>
export default {
  name: "Pagination",
  props: {
    totalPages: {
      type: Number,
      default: 5
    },
    currentPage: {
      type: Number,
      default: 1
    }
  },
  methods: {
    goToPage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit('page-changed', page);
      }
    }
  },
  computed: {
    displayedPages() {
      const pages = [];
      const start = Math.max(1, this.currentPage - 2);
      const end = Math.min(this.totalPages, start + 4); // 显示最多 5 个按钮

      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      return pages;
    }
  }
}
</script>

<template>
  <div class="pagesize">
    <div class="brad">
      <a href="#" @click.prevent="goToPage(currentPage - 1)" >
        <img src="images/left.png" alt="Previous">
      </a>
      <a
          v-for="page in displayedPages"
          :key="page"
          :class="{ current: page === currentPage }"
          @click.prevent="goToPage(page)"
          href="#"
      >
        {{ page }}
      </a>
      <input
          type="text"
          class="words"
          placeholder="..."
          @keyup.enter="goToPage(Number($event.target.value))"
      />
      <a href="#" @click.prevent="goToPage(totalPages)" v-if="totalPages > 1">
        {{ totalPages }}
      </a>
      <a href="#" @click.prevent="goToPage(currentPage + 1)" v-if="currentPage < totalPages">
        <img src="images/right.png" alt="Next">
      </a>
    </div>
  </div>
</template>

<style scoped>

</style>