<script>
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";

export default {
  name: "CreateTokenView",
  components: {FooterComponent, HeaderComponent},
  data: function () {

  },
  computed: {},
  methods: {},
  mounted() {

  }
}
</script>

<template>
  <div>
    <HeaderComponent/>
    <div class="index">
      <div class="section6">
        <div class="wrap">
          <div class="left">
            <div class="block1">
              <div class="lef">
                <div class="title">
                  <div class="txt">Extra Options</div>
                  <div class="switchModel">
                    <input type="checkbox" name="Storage" id="switch1">
                    <label for="switch1"><em></em></label>
                  </div>
                </div>
                <div class="sendfile">
                  <div class="pic">
                    <div class="default">
                      <span>jpeg｜png｜webp｜gif ( < 5MB )</span>
                    </div>
                    <button class="btn" type="button">Select Media</button>
                  </div>
                </div>
              </div>
              <div class="rig">
                <div class="title">Token</div>
                <div class="formlist">
                  <input type="text" placeholder="Name" class="words">
                  <input type="text" placeholder="Ticker Symbol" class="words">
                  <input type="text" placeholder="Description" class="words">
                </div>
              </div>
            </div>
            <div class="block2">
              <div class="title">Raised Token</div>
              <div class="list">
                <div class="item current">
                  <img src="images/ic2d.png" alt="">
                  <span>BNB</span>
                </div>
<!--                <div class="item">-->
<!--                  <img src="images/ic2d.png" alt="">-->
<!--                  <span>CAKE</span>-->
<!--                </div>-->
              </div>
            </div>
            <div class="block3">
              <div class="item1">
                <div class="ms1">
                  <div class="tit">Raised Amount</div>
                  <div class="icon"><img src="images/ic2d.png" alt=""></div>
                </div>
                <div class="ms2">
                  <b>$2500</b>
                  <span>$  16,005.12</span>
                </div>
                <div class="ms3">The minimum amount needs to be greater than $2,000</div>
                <div class="ms4">
                  <p class="current">$2500</p>
                  <p>$5000</p>
                  <p>$10000</p>
                </div>
                <div class="ms5"><a href="#">Other</a></div>
              </div>
              <div class="item2">
                <div class="tit">Optional</div>
                <div class="link">
                  <a href="#">
                    <span class="icon"><img src="images/ig1.png" alt=""></span>
                    <span>WWW.ABCD.MEME</span>
                  </a>
                  <a href="#">
                    <span class="icon"><img src="images/ig2.png" alt=""></span>
                    <span>Optional</span>
                  </a>
                  <a href="#">
                    <span class="icon"><img src="images/ig3.png" alt=""></span>
                    <span>Optional</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="block4">
              <button class="words">Submit</button>
            </div>
          </div>
          <div class="right">
            <div class="blocks1">
              <div class="lef">
                <div class="tit">Start Time</div>
                <div class="card">
                  <div class="ms1">
                    <b>2024 <br>Dec</b>
                    <span>31</span>
                  </div>
                  <div class="ms2">
                    <b>AM</b>
                    <span>12:00</span>
                  </div>
                </div>
              </div>
              <div class="rig">
                <div class="ms1">
                  <div class="s1">
                    <p>Initial Price</p>
                    <b>BNB</b>
                  </div>
                  <div class="s2">0.0000000077</div>
                </div>
                <div class="ms2">
                  <div class="dec">The biggest increase before list on PancakeSwap</div>
                  <div class="val">+1,436.64%</div>
                </div>
              </div>
            </div>
            <div class="blocks2">
              <div class="tit">Total Supply</div>
              <div class="val">1,000,000,000</div>
            </div>
            <div class="blocks3">The minimum amount needs to be greater than 1,000,000</div>
            <div class="blocks4">
              <div class="tit">Maximum per user</div>
              <div class="vals">
                <input type="text" placeholder="0" class="words">
                <div class="txt">BNB <img src="images/ic2d.png" alt=""></div>
              </div>
            </div>
            <div class="blocks5">
              <div class="tit">
                <p>Sales Ratio</p>
                <p>Liquidity Pool Ratio</p>
              </div>
              <div class="loadings">
                <p class="p1">80%</p>
                <p class="p2"><span>0%</span><b>Reserved Ratio</b></p>
                <p class="p3">20%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent/>
  </div>
</template>

<style scoped>

</style>